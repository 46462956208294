
const alertNews = {

  getNewestAlert: () => {
    const url = "/json/newest-alert"
    const xhttp = new XMLHttpRequest()
    let urlAlertNews = window.innerWidth < 1024 ? '/noticia/amp/v2' : '/noticia'

    xhttp.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200) {
        let html = ''
        const response = JSON.parse(this.response)

        if (response.length) {
          $('[data-alert]').addClass('-active')

          response.forEach((item, key) => {
            html += `<div class="item _flex">
                      <a href="${urlAlertNews}/${item.slug}"
                        id="Link_mais_detalhes_${key+1}"
                        data-category="Barra_de_alertas"
                        data-action="Clique_link_mais_detalhes"
                        data-label="Barra_de_alertas_geral"
                        class="link actTriggerGA">
                        <h3 class="title">${item.title} <span>Mais detalhes</span></h3> 
                      </a>
                    </div>
                    `
          })

          $('[data-alert-carousel]').html(html).owlCarousel({
            autoplay: true,
            autoplayHoverPause: true,
            dots: false,
            items: 1,
            lazyLoad: true,
            loop: true,
            rewind: true
          })

          $('[data-alert-next]').click(function() {              
            $(`[data-alert-carousel]`).trigger('next.owl.carousel')
          })
      
          $('[data-alert-prev]').click(function() {              
            $(`[data-alert-carousel]`).trigger('prev.owl.carousel')
          })
        } else {
          $('.D_patrocinio_sticky').removeClass('_none')
        }
      }
    }

    xhttp.open("POST", url, true)
    xhttp.send()
  },

  closeAlert: () => {
    $('[data-close-alert]').click(function() {
      $('[wrapper-alert-content]').addClass('_none')
      $('[data-alert]').removeClass('-active')
      $('.D_patrocinio_sticky').removeClass('_none')
    })
  },

}

export default alertNews
