import 'owl.carousel'

const carousel = {
  initCarousel: () => {
    $('[data-owl-carousel]').each(function() {
      const items = $(this).data('items')
      const itemsMobile = $(this).data('items-mobile')
      const dots = $(this).data('dots')
      const nav = $(this).data('nav')
      
      let owl = {
        dots,
        nav,
        lazyLoad: true,
        loop: true,
        touchDrag: true,
        responsive: {
          0: {
            items: itemsMobile || 1
          },
          1024: {
            items
          }
        }
      }
  
      if ($(this).data('autoplay')) {
        owl.autoplay = true
        owl.autoplayHoverPause = true
        owl.autoplayTimeout = 5000
        owl.rewind = true
      }
  
      $(this).owlCarousel(owl)

      $('.owl-carousel').each(function(){
        $(this).children('.owl-dots').attr("data-element-name", "dots-navigation-carousel").children().each(function(index){
          $(this).attr("data-element-name", "dots-navigation-item");
          $(this).attr("data-sequence", index+1);
          $(this).attr("aria-label", `dot_${index+1}`) 
        })
      })
    })
  },

}

export default carousel
