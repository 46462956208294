const backgroundBanner = {
  /**
   * Muda comportamento da página se tiver banner background ativo
   *
   */
  renderBackgroundBanner: () => {
    let timer = setInterval(function () {
      if (!($('[data-forecast-page="amanha"]')||$('[data-forecast-page="agora"]')||$('[data-forecast-page="fds"]'))) {
        $('[data-id="banner-mega-featured"]').each(function () {
          // se tiver bg, o iframe assume width maior que 1
          if ($(this).children().children().prop("width") > 1) {
            // acrescenta opacidade para mostrar o bg
            $(this).parent().addClass("-opacity");
            // empurra conteúdo da página para ajustar ao banner
            $('[data-id="mainContainer"]').addClass("has-mega-featured");
            // limpa o interval para não continuar percorrendo os banners
            clearInterval(timer);
            // remove a div do superbanner no topo
            $('[data-id="super-banner"]').remove();
          }
        });
      }
      // percorre divs que podem conter mega destaque
    }, 2000);
  },
};

export default backgroundBanner;
