const renderFaqHead = () => {
  const faqData = [
    {
      question: "O que é clima?",
      answer:
        "Clima é a caracterização das condições atmosféricas de uma determinada região através de registros de um longo período de tempo. Estas condições incluem <strong>temperatura, chuva, pressão atmosférica, umidade do ar</strong> e <strong>vento</strong>, por exemplo. O clima depende de diversos fatores, como circulação atmosférica, localização geográfica, altitude e proximidade com o oceano.<br><br>Existe o clima tropical, equatorial, subtropical, temperado, semiárido, árido e outros.<br><br>As atividades humanas estão provocando mudanças climáticas globais, através do aumento das emissões de gases de efeito estufa. Assim, ocorre o aumento da temperatura média da Terra e a intensificação de eventos climáticos extremos, como tempestades severas e secas mais prolongadas.",
    },
    {
      question: "Qual a diferença entre tempo e clima?",
      answer:
        "Os conceitos de <strong>tempo</strong> e clima estão relacionados às condições atmosféricas de um determinado local ou região, sendo diferenciados principalmente em relação ao período de tempo considerado.<br><br>O tempo é o estado atual da atmosfera de um local, que pode mudar de um dia para o outro, ou até mesmo, de uma hora para outra. Por exemplo, quando dizemos “Hoje está chovendo em São Paulo” ou “Amanhã a previsão é de sol e temperaturas amenas” estamos nos referindo ao tempo, que varia de horas, dias a semanas.<br><br>Já o clima representa a média das variações do tempo por um período mais longo de uma determinada região, tratando-se de meses, estações do ano e anos. Por exemplo, quando dizemos “O inverno no sudeste do Brasil é seco e o verão chuvoso” ou então “A previsão climática é de que a temperatura do ar fique acima da média no sul em agosto”, estamos nos referindo a clima.",
    },
    {
      question: "Como é feita a previsão do tempo na Climatempo?",
      answer:
        "A <strong>previsão do tempo</strong> é uma descrição detalhada do comportamento da atmosfera esperado no futuro, ou seja um prognóstico ou estimativa do estado do tempo para as próximas horas, dias e semanas. Para fazer a previsão, os meteorologistas utilizam como base modelos meteorológicos objetivos, com diferentes resoluções e que consideram parâmetros atmosféricos. Na Climatempo, são analisados os mais diversos tipos de dados e modelos, combinando-os com a experiência e habilidade de nossos profissionais para chegar a um consenso.",
    },
    {
      question: "O que a meteorologia estuda?",
      answer:
        "A <strong>meteorologia</strong> é uma área da ciência que estuda o comportamento da atmosfera, integrando o conhecimento de diversas áreas. Por ser multidisciplinar, envolve disciplinas como física, matemática, ciência da computação, estatística e química.",
    },
    {
      question: "Qual a diferença entre calor e temperatura?",
      answer:
        "<strong>Temperatura</strong> é uma propriedade física e é definida como a medida da agitação das moléculas de um corpo. Quanto maior a agitação dessas partículas, maior a temperatura do corpo. Ela é medida por um termômetro, geralmente em unidades de graus Celsius (°C) ou Fahrenheit (°F).<br><br>Já o calor é a energia transferida entre dois corpos que apresentam diferença entre suas temperaturas. O calor pode ser transferido por condução, convecção ou radiação. As suas unidades de medida são joules ou calorias.<br><br>A <strong>temperatura do ar</strong> é a quantidade de calor presente no ar, sendo uma das principais variáveis do clima.",
    },
    {
      question: "Como são formadas as nuvens de chuva?",
      answer:
        "As <strong>nuvens de chuva</strong> são formadas a partir da evaporação das águas da superfície da terra e do levantamento de parcelas de ar quentes e úmidas.<br><br>Quando a água evapora, ela se transforma em vapor de água, que é mais leve do que o ar à sua volta. Isso faz com que o ar próximo à superfície se torne mais úmido e menos denso do que o ar acima dele. A parcela de ar mais leve tende a se elevar. À medida que sobe, ela se expande e resfria, o que provoca a condensação do vapor de água presente na parcela de ar. Esse processo leva à formação de nuvens e, eventualmente, à precipitação.<br><br>O levantamento das parcelas de ar ocorre através do processo de convecção e fenômenos como frentes frias e quentes, convergência de umidade ou até mesmo a topografia.",
    },
  ];

  const headScriptJson = `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "${faqData[0].question}",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "${faqData[0].answer}"
        }
      }, {
        "@type": "Question",
        "name": "${faqData[1].question}",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "${faqData[1].answer}"
        }
      }, {
        "@type": "Question",
        "name": "${faqData[2].question}",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "${faqData[2].answer}"
        }
      }, {
        "@type": "Question",
        "name": "${faqData[3].question}",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "${faqData[3].answer}"
        }
      }, {
        "@type": "Question",
        "name": "${faqData[4].question}",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "${faqData[4].answer}"
        }
      }]
    }
    </script>`;

  document.querySelector("head").innerHTML += headScriptJson;
};

const handleAnswerOpening = () => {
  const faqItems = document.querySelectorAll(".faq-item");

  window.dataLayer = window.dataLayer || [];

  faqItems.forEach((item, index) => {
    const question = item.querySelector(".question");
    const answer = item.querySelector(".faq-item p");
    const button = item.querySelector(".question button");

    question.addEventListener("click", () => {
      if (!button.classList.contains("rotate")) {
        window.dataLayer.push({
          event: "open_accordion",
          id: `Accordion_FaqClimatempo_Item_Questao-0${index + 1}`,
          name: "Accordion_FaqClimatempo",
        });
      } else {
        window.dataLayer.push({
          event: "close_accordion",
          id: `Accordion_FaqClimatempo_Item_Questao-0${index + 1}`,
          name: "Accordion_FaqClimatempo",
        });
      }

      item.classList.toggle("border-radius");
      button.classList.toggle("rotate");
      answer.classList.toggle("none-display");
    });
  });
};

const accordionFaq = {
  renderFaqHead,
  handleAnswerOpening,
};

export default accordionFaq;
